<template>
  <div>
    <div class="vx-row">
      <feather-icon
        @click="back()"
        :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <p class="text-xl">
        {{ $t("YourPatients") }}
      </p>
    </div>
    <vx-card class="mt-4 m-4">
      <vs-row vs-w="12" vs-type="flex" vs-justify="center">
        <vs-col vs-lg="2" vs-sm="12" vs-xs="12" vs-type="flex" class="mt-2"
          >{{ $t("SearchPatient") }}
        </vs-col>
        <vs-col vs-lg="5" vs-sm="3" vs-xs="10" vs-type="flex">
          <vs-input
            name="Patient Name"
            class="w-full"
            :placeholder="$t('PatientName')"
            v-model="Search.PatientName"
          />
        </vs-col>

        <vs-col vs-sm="2" vs-lg="1" vs-xs="2" vs-type="flex">
          <feather-icon
            icon="SearchIcon"
            svgClasses=" w-16"
            class="mt-3"
            @click="searchData(2)"
          />
        </vs-col>
      </vs-row>
    </vx-card>
    <h3 v-if="!Model" class="w-full sm:w-auto text-center text-primary mt-5 mb-5">
      {{ $t("NoDataToshow") }}
    </h3>
    <div class="pl-10 pr-10" v-for="item in Model" :key="item.ID">
      <div v-if="item" tabindex="0" class="e-card e-card-horizontal">
        <div class="vx-row">
          <div class="vx-col lg:w-1/5">
            <imageLazy
              :imageStyle="{ height: '40px', width: '40px' }"
              :imageClass="'rounded-full'"
              :src="baseURL + item.ProfileImagePath"
              placeHolderType="patient"
            />
            <p class="product-name font-medium truncate">
              {{ item.Name }}
            </p>
          </div>
          <div class="vx-col lg:w-2/5">
            <div>
              <a @click="showPatientMedicalHistory(item.ID)" class="linkClass">
                {{ $t("PatientMedicalHistory") }}
              </a>
            </div>

            <div>
              <a @click="showPatientReservationHistory(item.ID)" class="linkClass">
                {{ $t("ReservationHistory") }}
              </a>
            </div>
          </div>
          <div class="vx-col lg:w-2/5" v-if="item.NextAppointmentDate">
            <div>
              <h5>
                {{ $t("NextAppointment") }} :
                {{ item.NextAppointmentDate }}
              </h5>
            </div>
          </div>

          <div class="borderClass mt-2 mb-2"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import { domain } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";

export default {
  data() {
    return {
      EnglishLan: false,
      baseURL: domain,
      Search: {},
      Model: [],
    };
  },
  components: {
    imageLazy,
  },
  methods: {
    showPatientMedicalHistory(PatientID) {
      debugger;
      this.$router.push({
        name: "PatientMedicalHistorySession",
        params: { ID: PatientID },
      });
    },
    showPatientReservationHistory(PatientID) {
      this.$router.push("/PatientReservation/" + this.$route.params.ID + "/" + PatientID);
    },
    back() {
      this.$router.go(-1);
    },
    searchData(index) {
      debugger;
      if (this.$route.params.ID) {
        var model = {};
        model.doctorID = this.$route.params.ID;
        if (index == 2) {
          if (this.Search.PatientName != "") {
            model.PatientName = this.Search.PatientName;
          }
        }
        this.$store
          .dispatch("DoctorList/GetDoctorPatients", model.doctorID)
          .then((res) => {
            if (res.status == 200) {
              debugger;

              this.Model = res.data.Data;
              debugger;
              //.filter(obj=>obj.Name.includes( model.PatientName ));
              if (
                (index =
                  2 &&
                  this.Search.PatientName != undefined &&
                  this.Search.PatientName != "")
              )
                this.Model = res.data.Data.filter((obj) =>
                  obj.Name.includes(model.PatientName)
                );
              if (this.Model == null || this.Model.length == 0) {
                this.$vs.notify({
                  title: this.$t("NoData"),
                  text: this.$t("NoDataToshow"),
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "warning",
                });
              }
            }
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
      var x = this.Model.length;
      debugger;
    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" || localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    this.searchData(1);
  },
};
</script>

<style>
.borderClass {
  width: 100%;
  height: 2px;
  border: 1px solid #e7e7e7;
  opacity: 1;
}
.linkClass {
  text-decoration: underline;
  font: normal normal normal 15px/22px Futura PT;
  letter-spacing: 0px;
  color: #454a62;
  text-transform: capitalize;
  opacity: 1;
  cursor: pointer;
}
</style>
